<template>
    <div
        style="background: black; width: calc(100% - 19px); height: 400px; overflow-y: scroll; padding: 5px 5px 5px 10px; margin-top: 20px;"
        ref="terminal">
        <p v-for="(item, index) in rows" :key="index"
           :class="{ error: (item.type == 'error') }">
            {{ item.data }}
        </p>
    </div>
</template>

<script>
import {mapState} from "pinia/dist/pinia";
import {useTerminalStore} from "@/stores/terminal";

export default {
    name: "BluetoothTerminal",
    computed: {
        ...mapState(useTerminalStore, ['rows'])
    },
    watch: {
        rows: {
            handler() {
                this.$nextTick(() => this.$refs.terminal.scrollTop = this.$refs.terminal.scrollHeight);
            },
            deep: true
        }
    }
}

</script>

<style scoped>
p {
    font-family: Consolas, monaco, monospace;
    font-size: 12px;
    color: white;
    line-height: 12px;
    text-align: left;
    margin-top: 7px;
    margin-bottom: 7px
}

p.error {
    color: #a50000;
}
</style>