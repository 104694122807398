<template>
    <div>
        <p>
            To get started, click the scan button below
        </p>

        <button v-if="!device" class="uk-button uk-button-default" @click="scan">
            Scan for Devices
        </button>

        <button v-if="device" class="uk-button uk-button-default" @click="download" :disabled="!deviceReady">
          Download Data
        </button>
        <button v-if="device" class="uk-button uk-button-danger" @click="clearDataClick" :disabled="!deviceReady">
          Clear Data
        </button>
        <button v-if="device" class="uk-button uk-button-default" @click="time" :disabled="!deviceReady">
          Set Time
        </button>
        <button v-if="device" class="uk-button uk-button-default" @click="update" :disabled="!deviceReady">
          Update Firmware
        </button>
        <button v-if="device" class="uk-button uk-button-default" @click="disconnect" :disabled="!deviceReady">
            Disconnect
        </button>

    </div>
</template>

<script>
import {attemptFirmwareUpdate, setTime, connect, disconnect, downloadData, clearData} from "@/services/bluetooth";
import {mapState} from "pinia/dist/pinia";
import {useBluetoothStore} from "@/stores/bluetooth";

export default{
    name: "BluetoothScan",
    computed:{
        ...mapState(useBluetoothStore, ['device', 'deviceReady'])
    },
    data(){
      return {
        deviceCache: null,
        characteristicCache: null,
      }
    },
    methods: {
        scan(){
          let bluetooth = useBluetoothStore();
          connect(bluetooth.device, bluetooth.characteristic);
        },
        update(){
            attemptFirmwareUpdate()
        },
        time(){
            setTime()
        },
        download(){
            downloadData()
        },
        clearDataClick(){
            this.$swal({
                title: 'Delete Data?',
                text: 'This will permanently delete all the data on the device. Are you sure you want to clear the devices data?',
                icon: 'error',
                confirmButtonText: 'Yes, Delete Data',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("deleting data");
                    clearData();
                }
            })
        },
        disconnect(){
          let bluetooth = useBluetoothStore();

          disconnect(bluetooth.device, bluetooth.characteristic);
          bluetooth.disconnect();
        }
    }
}

</script>