import { useTerminalStore } from '@/stores/terminal'
import { useBluetoothStore } from '@/stores/bluetooth'

//Core Service UUID (should all devices should have this UUID?)
let myESP32 = 'd804b643-6ce7-4e81-9f8a-ce0f699085eb';
let otaServiceUuid =             'c8659210-af91-4ad3-a995-a58d6fd26145';
let fileCharacteristicUuid =     'c8659211-af91-4ad3-a995-a58d6fd26145';
let versionCharacteristicUuid =  'c8659212-af91-4ad3-a995-a58d6fd26145';
let ntpCharacteristicUuid =      'c8659213-af91-4ad3-a995-a58d6fd26145';
let downloadCharacteristicUuid = 'c8659214-af91-4ad3-a995-a58d6fd26145';
let metadataCharacteristicUuid = 'c8659215-af91-4ad3-a995-a58d6fd26145';
let clearDataCharacteristicUuid = 'c8659216-af91-4ad3-a995-a58d6fd26145';

// let esp32Device = null;
let esp32Service = null;
let readyFlagCharacteristic = null;
let dataToSend = null;
let updateData = null;

let totalSize;
let remaining;
let amountToWrite;
let currentPosition;

let currentHardwareVersion = "N/A";
let softwareVersion = "N/A";
let latestCompatibleSoftware = "N/A";

const characteristicSize = 512;

let readBuffer = '';

export function connect(deviceCache, characteristicCache) {
  return (deviceCache ? Promise.resolve(deviceCache) :
    requestBluetoothDevice())
    .then(device => connectDeviceAndCacheCharacteristic(characteristicCache, device))
    .then(service => esp32Service = service)
    .then(service => checkVersion(service))
    .then(() => getMetadata(esp32Service))
    .then(() => {
        const bluetoothStore = useBluetoothStore();
        bluetoothStore.setDeviceStatus(true);
    })
    // .then(characteristic => startNotifications(characteristic)) //TODO fix terminal connection feature
    .catch(error => log(error));
}

export function requestBluetoothDevice() {
  log('Requesting bluetooth device...');

  return navigator.bluetooth.requestDevice({
    filters: [
      {
        services: [myESP32]
      }
    ],
    optionalServices: [
      otaServiceUuid
    ]
  }).then(device => {
    log('"' + device.name + '" bluetooth device selected');

    const bluetoothStore = useBluetoothStore();
    bluetoothStore.setDevice(device);

    // device.addEventListener('gattserverdisconnected',
    //   handleDisconnection);

    //TODO set the device to the store here

    return device;
  });
}

export function attemptFirmwareUpdate(){
  log("Fetching latest firmware version... (" + latestCompatibleSoftware + ")" )

  fetch('https://raw.githubusercontent.com/Humni/people-counter/' + latestCompatibleSoftware + '/firmware/' + currentHardwareVersion + '.bin')
    .then(function (response) {
      log("Firmware downloaded!");
      return response.arrayBuffer();
    })
    .then(function (data) {
      log("Uploading firmware to the device...");
      updateData = data;
      return SendFileOverBluetooth(fileCharacteristicUuid);
    })
    .catch(function (err) { console.warn('Something went wrong.', err); });
}

export function setTime(){
  if(!esp32Service)
  {
    log("No Device Connected", 'error');
    return;
  }

  let time = getTimestampInSeconds();
  log("Sending current unix time to device: " + time);

  updateData = new Int32Array([getTimestampInSeconds()]).buffer;

  return SendFileOverBluetooth(ntpCharacteristicUuid);
}

function getTimestampInSeconds () {
  const now = new Date;
  return Math.floor(Date.UTC(now.getFullYear(),now.getMonth(), now.getDate() ,
      now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()) / 1000)
}

/* SendFileOverBluetooth(data)
 * Figures out how large our update binary is, attaches an eventListener to our dataCharacteristic so the Server can tell us when it has finished writing the data to memory
 * Calls SendBufferedData(), which begins a loop of write, wait for ready flag, write, wait for ready flag...
 */
export function SendFileOverBluetooth(_characteristic = null) {
  if(!_characteristic){
    _characteristic = fileCharacteristicUuid;
  }
  if(!esp32Service)
  {
    log("No Device Connected", 'error');
    return;
  }

  totalSize = updateData.byteLength;
  remaining = totalSize;
  amountToWrite = 0;
  currentPosition = 0;
  esp32Service.getCharacteristic(_characteristic)
    .then(async characteristic => {
        readyFlagCharacteristic = characteristic;

        let bluetooth = useBluetoothStore();
        bluetooth.setCharacteristic(characteristic);

        console.log("characteristic", JSON.stringify(characteristic), characteristic, characteristic.startNotifications);


        characteristic.addEventListener('characteristicvaluechanged', (event) => {
            SendBufferedData(_characteristic)
            let value = new TextDecoder().decode(event.target.value)

            if(
                value[0] === 1 &&
                value[1] === 2 &&
                value[2] === 3 &&
                value[3] === 4 &&
                value[4] === 5
            ){
                log("NTP Time Set Successfully");
                //TODO fix this check, it doesn't work
            }
        });
        try {
            await characteristic.startNotifications();
        } catch (e){
            console.log("characteristic.startNotifications()", e)
            log(e, 'error');
        }

        // return characteristic.startNotifications()
        //     .then(() => {
        //         readyFlagCharacteristic.addEventListener('characteristicvaluechanged', () => {
        //             SendBufferedData(_characteristic)
        //         });
        //     })
        //     .catch(error => {
        //         console.log("characteristic.startNotifications()", error)
        //         log(error, 'error');
        //     });
    })
    .catch(error => {
        console.log("esp32Service.getCharacteristic(_characteristic)", error)
        log(error, 'error');
    });

  SendBufferedData(_characteristic);
}


/* SendBufferedData()
 * An ISR attached to the same characteristic that it writes to, this function slices data into characteristic sized chunks and sends them to the Server
 */
let outputLag = 0;
function SendBufferedData(_characteristic = null) {
  if(!_characteristic){
    _characteristic = fileCharacteristicUuid;
  }

  if (remaining > 0) {
    if (remaining >= characteristicSize) {
      amountToWrite = characteristicSize
    }
    else {
      amountToWrite = remaining;
    }
    dataToSend = updateData.slice(currentPosition, currentPosition + amountToWrite);
    currentPosition += amountToWrite;
    remaining -= amountToWrite;

    outputLag++;
    if(outputLag % 10 === 0) {
      let remainingPercentage = (100 * (currentPosition/totalSize)).toPrecision(3);
      log("Progress: " + remainingPercentage + "% (" + remaining + " bytes remaining)");
    }

    esp32Service.getCharacteristic(_characteristic)
      .then(characteristic => RecursiveSend(characteristic, dataToSend))
      // .then(() => {
      //   return document.getElementById('completion').innerHTML = (100 * (currentPosition/totalSize)).toPrecision(3) + '%';
      // })
      .catch(error => {
        log(error, 'error')
        console.error("esp32Service.getCharacteristic(_characteristic)", error);
      });
  }
}


/* resursiveSend()
 * Returns a promise to itself to ensure data was sent and the promise is resolved.
 */
let errorRepeat = 0;
function RecursiveSend(_characteristic, data) {
  console.log("Sending the following data as a packet", data);

  return _characteristic.writeValue(data)
    .then(() => errorRepeat = 0)
    .catch(error => {
      log(error, 'error')
      console.error("RecursiveSend::writeValue Error", error);

      errorRepeat ++;
      if(errorRepeat >= 10) return;

      if(error?.stack?.startsWith("Error: Failed to execute 'writeValue' on 'BluetoothRemoteGATTCharacteristic': GATT Server is disconnected. Cannot perform GATT operations. (Re)connect first with `device.gatt.connect`.")) {
        // log("Attempting to reconnect...")

        // connectDeviceAndCacheCharacteristic()
        //   .then(service => esp32Service = service)
        //   .then(() => {
        //     return esp32Service.getCharacteristic(fileCharacteristicUuid)
        //       .then(_characteristic => {
        //         readyFlagCharacteristic = _characteristic;
        //         return _characteristic.startNotifications()
        //           .then(() => {
        //             readyFlagCharacteristic.addEventListener('characteristicvaluechanged', SendBufferedData)
        //             characteristic = readyFlagCharacteristic;
        //           });
        //       })
        //   }).then(() => {
        //     log("Reconnect Successful...")
        //   })
        //   .catch(error => {
        //     log(error)
        //     console.log(error);
        //   })
      }

      return RecursiveSend(_characteristic, data);
    });
}

// export function handleDisconnection(event) {
//   let device = event.target;
//
//   log('"' + device.name +
//     '" bluetooth device disconnected, trying to reconnect...');
//
//   connectDeviceAndCacheCharacteristic(null, device)
//     // .then(characteristic => startNotifications(characteristic))
//     .catch(error => log(error));
// }

export function disconnect(deviceCache, characteristicCache) {
  if (deviceCache) {
    log('Disconnecting from "' + deviceCache.name + '" bluetooth device...');
    // deviceCache.removeEventListener('gattserverdisconnected',f
    //   handleDisconnection);

    if (deviceCache.gatt.connected) {
      deviceCache.gatt.disconnect();
      log('"' + deviceCache.name + '" bluetooth device disconnected');
    } else {
      log('"' + deviceCache.name +
        '" bluetooth device is already disconnected');
    }
  }

  // Added condition
  if (characteristicCache) {
    characteristicCache = null;
  }

  const bluetoothStore = useBluetoothStore();
  bluetoothStore.setDeviceStatus(false);
}

export function connectDeviceAndCacheCharacteristic(characteristicCache, device) {
  if (device.gatt.connected && characteristicCache) {
    return Promise.resolve(characteristicCache);
  }

  log('Connecting to GATT server...');

  return device.gatt.connect().then(async server => {
      log('GATT server connected, getting service...');

      let service;
      try {
          service = await server.getPrimaryService(otaServiceUuid);
      } catch (e) {
          console.error("server.getPrimaryService(otaServiceUuid)", e);
          log(e, 'error')
          log("Re-establishing connection to the device...")

          let server = await device.gatt.connect();
          service = await server.getPrimaryService(otaServiceUuid);
      }

      console.log(await service.getCharacteristics()); //help debug services being broadcasted by the device

      return service
  }).then(service => {
    log('Service found, getting characteristic...');

    return service;
  })
  .catch(error => {
      console.error("connectDeviceAndCacheCharacteristic(characteristicCache, device)", error);
      log(error, 'error')
      disconnect(null, null);
      throw new Error("Can't Connect to Device");
  });
}

export function checkVersion(service){
  if(!service) {
    return
  }
  log('Checking system version...');

  return service.getCharacteristic(versionCharacteristicUuid)
    .then(characteristic => characteristic.readValue())
    .then(value => {
      currentHardwareVersion = 'v' + value.getUint8(0) + '.' + value.getUint8(1);
      softwareVersion = 'v' + value.getUint8(2) + '.' + value.getUint8(3) + '.' + value.getUint8(4);

      log('Current Hardware Version: ' + currentHardwareVersion + ' Software Version: ' + softwareVersion);
    })
    //Grab our version numbers from Github
    .then(() => fetch('https://raw.githubusercontent.com/Humni/people-counter/master/firmware/version.json'))
    .then(function (response) {

      log('Fetched current firmware versions from github');
      // The API call was successful!
      return response.json();
    })
    .then(function (data) {
      // JSON should be formatted so that 0'th entry is the newest version
      if (latestCompatibleSoftware === softwareVersion)
      {
        log('Firmware is up to date');
        //Software is updated, do nothing.
      }
      else {
        log('Firmware is out of date! Scanning for latest veresion...');
        var softwareVersionCount = 0;
        latestCompatibleSoftware = data.firmware[softwareVersionCount]['software'];

        versionFindLoop:
          while (latestCompatibleSoftware !== undefined) {
            console.log("latestCompatibleSoftware", latestCompatibleSoftware);

            let compatibleHardwareVersion = "N/A"
            let hardwareVersionCount = 0;

            while (compatibleHardwareVersion !== undefined) {
              console.log("compatibleHardwareVersion", compatibleHardwareVersion);

              compatibleHardwareVersion = data.firmware[softwareVersionCount]['hardware'][hardwareVersionCount++];
              if (compatibleHardwareVersion === currentHardwareVersion)
              {
                latestCompatibleSoftware = data.firmware[softwareVersionCount]['software'];

                if (latestCompatibleSoftware !== softwareVersion)
                {
                  console.log("latestCompatibleSoftware changed to:", latestCompatibleSoftware);
                  //TODO notify the user that an update is required
                  log("Please update to version " + latestCompatibleSoftware, 'error')
                }
                break versionFindLoop;
              }

              if(hardwareVersionCount > 100000) {
                log ("Could not find the latest version of firmware");
                console.error("Debug data for version not found (data, hardwareVersionCount, softwareVersionCount, latestCompatibleSoftware)",
                    data,
                    hardwareVersionCount,
                    softwareVersionCount,
                    latestCompatibleSoftware
                )
                break versionFindLoop;
              }
            }
            softwareVersionCount++;
          }
      }
      log("No firmware versions found for this version of hardware", 'error');

      return true;
    })
    .catch(error => {
      console.error(error);
      log(error, 'error')
    });
}


export function getMetadata(service){
  log('Fetching system metadata...');

  if(!service) {
    return
  }

  return service.getCharacteristic(metadataCharacteristicUuid)
      .then(characteristic => characteristic.readValue())
      .then(value => {
        console.log(value);
        console.log(value.getUint8(0))
        console.log(value.getUint8(1))
        console.log(value.getUint8(2))
        console.log(value.getUint8(3))
        console.log(value.getUint8(4))
        console.log(value.getUint8(5))
        console.log(value.getUint8(6))
        console.log(value.getUint8(7))
        console.log(value.getUint8(8))
        console.log(value.getUint8(9))
        console.log(value.getUint8(10))
        console.log(value.getUint8(11))
        console.log(value.getUint8(12))
        console.log(value.getUint8(13))
        console.log(value.getUint8(14))
        // currentHardwareVersion = 'v' + value.getUint8(0) + '.' + value.getUint8(1);
        // softwareVersion = 'v' + value.getUint8(2) + '.' + value.getUint8(3) + '.' + value.getUint8(4);
        let epoch = value.getUint32(0);
        let totalStorage = value.getUint32(5);
        let usedStorage = value.getUint32(10);
        let storageUsedPercent = usedStorage/totalStorage * 100;
        storageUsedPercent = Math.round(storageUsedPercent * 10) / 10; //rounded to 1dp;

        log('Current Time: ' + new Date(epoch * 1000).toISOString());
        log('Storage: ' + usedStorage + "/" + totalStorage + " used (" + storageUsedPercent + "%) ");
      })
      .catch(error => {
        console.error("service.getCharacteristic(metadataCharacteristicUuid)", error);
        log(error, 'error')
      });
}


export function clearData(){
    if(!esp32Service)
    {
        log("No Device Connected");
        return;
    }

    log('Clearing all data on the device...');

    return esp32Service.getCharacteristic(clearDataCharacteristicUuid)
        .then(characteristic => writeToCharacteristic(characteristic, "clear"))
        .then(() => {
            log('Data from device Cleared!');
        })
        .catch(error => {
            console.error("esp32Service.getCharacteristic(clearDataCharacteristicUuid)", error);
            log(error, 'error')
        });
}

// export function startNotifications() {
//   log('Starting notifications...');
//
//   //TOOD service connect to characteristic
//
//   return characteristic.startNotifications().then(() => {
//     log('Notifications started');
//
//     characteristic.addEventListener('characteristicvaluechanged',
//       handleCharacteristicValueChanged);
//   });
// }

export function downloadData() {
  if(!esp32Service)
  {
    log("No Device Connected", 'error');
    return;
  }

  log('Starting data download...');

  readBuffer = '';
  return esp32Service.getCharacteristic(downloadCharacteristicUuid)
      .then(characteristic => {
        readyFlagCharacteristic = characteristic;
        return characteristic.startNotifications()
            .then(() => {
                characteristic.readValue(); //trigger the download read
                readyFlagCharacteristic.addEventListener('characteristicvaluechanged', function handler(event) {
                  readBufferedData(event, characteristic, handler)
                });
            });
      })
      .catch(error => {
        console.log("esp32Service.getCharacteristic(downloadCharacteristicUuid)", error)
        log(error, 'error');
      });
}

export function readBufferedData(event, characteristic, handler) {
  let value = new TextDecoder().decode(event.target.value);

  log('Packet Received... total data: ' + readBuffer.length + ' packet length: ' + value.length);

  for (let c of value) {
    readBuffer += c;
  }

  if(value.length < 512){
    // stop packet hit, ending read
    const blob = new Blob([readBuffer], { type: 'text/csv' });

    downloadBlob(blob, 'counter.csv');

    readBuffer = '';

    log('Download Finished!');

    characteristic.removeEventListener('characteristicvaluechanged', handler);

  } else {
      characteristic.readValue(); //trigger the next value
  }
}

// from https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}

// Data receiving
export function handleCharacteristicValueChanged(event) {
  let value = new TextDecoder().decode(event.target.value);

  for (let c of value) {
    if (c === '\n') {
      let data = readBuffer.trim();
      readBuffer = '';

      if (data) {
        receive(data);
      }
    } else {
      readBuffer += c;
    }
  }
}

// Received data handling
export function receive(data) {
  log(data, 'in');
}


export function send(characteristicCache, data) {
  data = String(data);

  if (!data || !characteristicCache) {
    return;
  }

  data += '\n';

  if (data.length > 20) {
    let chunks = data.match(/(.|[\r\n]){1,20}/g);

    writeToCharacteristic(characteristicCache, chunks[0]);

    for (let i = 1; i < chunks.length; i++) {
      setTimeout(() => {
        writeToCharacteristic(characteristicCache, chunks[i]);
      }, i * 100);
    }
  } else {
    writeToCharacteristic(characteristicCache, data);
  }

  log(data, 'out');
}

function writeToCharacteristic(characteristic, data) {
  characteristic.writeValue(new TextEncoder().encode(data));
}

/**
 *
 * @param data
 * @param type 'in', 'out', 'error'
 */
export function log(data, type = '') {
  console.log(data, type)
  const terminal = useTerminalStore();
  terminal.addRow({type: type, data: data});
}