<template>
    <img alt="Vue logo" src="./assets/logo.png" style="max-height: 200px; padding-bottom: 10px">
    <WelcomeMessage/>
    <BluetoothScan />
    <BluetoothTerminal />
</template>

<script>
import WelcomeMessage from '@/components/WelcomeMessage.vue'
import BluetoothScan from '@/components/BluetoothScan.vue'
import BluetoothTerminal from "@/components/BluetoothTerminal";

export default {
    name: 'App',
    components: {
        BluetoothTerminal,
        WelcomeMessage,
        BluetoothScan
    },
}
</script>

<style>
@import "../node_modules/uikit/dist/css/uikit.css";
</style>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
