import { defineStore } from 'pinia'

export const useBluetoothStore = defineStore('bluetooth', {
  state: () => {
    return {
      device: null,
      characteristic: null,
      deviceReady: false
    }
  },
  actions: {
    disconnect() {
      this.device = null;
      this.characteristic = null;
    },
    setDevice(device) {
      this.device = device
    },
    setCharacteristic(characteristic) {
      this.characteristic = characteristic
    },
    setDeviceStatus(ready) {
      this.deviceReady = ready
    },
  },
})