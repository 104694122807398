import { defineStore } from 'pinia'

export const useTerminalStore = defineStore('terminal', {
  state: () => {
    return {
      rows: []
    }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    addRow(row) {
      this.rows.push(row);

      if(this.rows.length > 200) { //terminal only holds 1000 rows
        this.rows.shift();
      }
    },
  },
})